import React from "react";
import "./Sound.css";

export default function Sound (props)
{

    return (
        <div className="sound">
            <svg version="1.1" className={`iconSound ${props.playing ? "active" : "inactive"}`} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 595.3 841.9" xmlSpace="preserve">
                <g>
                    <path  d="M364,635.1c6.7-6.6,12.8-12.5,18.9-18.5c0.7,0.6,1.4,1.1,2.1,1.7c-7.4,15.1-15.1,30.2-25.4,43.5
                        C349.1,675.2,337.9,688,326,700c-12,12.1-25.3,22.8-38.1,34.1c-0.5-0.4-1.1-0.8-1.6-1.2c0.6-1.1,1-2.4,1.8-3.3
                        c24.4-25,46.2-52.1,56.7-85.9c7.6-24.6,10.1-50.2,1.4-75.6c-3.7-10.8-9.6-20.3-18.1-27.7c-11.2-9.7-23.1-18.5-34.8-27.6
                        c-0.8-0.7-2-0.9-3.8-1.6c4.5,7.3,8.6,13.8,12.7,20.3c-0.6,0.5-1.1,1-1.7,1.5c-1.3-0.8-2.7-1.5-3.8-2.6
                        c-13.1-13.5-26.2-27.1-39.3-40.6c-0.9-0.9-2-1.6-3.9-3.2c-1.6,6.1-3,11.5-4.7,18.1c-5-7.7-6-15.6-8-23.1c-2.1-8-3.3-16.3-4.5-24.5
                        c-1.1-8-1.7-16.1-3.7-24.7c-2.8,4.2-5.6,8.5-8.4,12.7c-0.5-0.3-1-0.6-1.5-0.9c2.6-22.2,12-42.1,22.6-61.6c-0.5-0.3-1-0.6-1.4-0.9
                        c-4.3,1.7-8.5,3.3-12.8,5c-0.4-0.4-0.8-0.7-1.2-1.1c7.8-17.2,21.3-30.1,35.6-43.8c-10.6-0.9-19.4,1.1-27.8,5.3
                        c-12.3,6.1-18,16.7-20.1,29.8c-0.9,5.5-2,6-6,2.3c-22.5-20.7-54.3-10.3-67.9,9.8c-10.9,16.2-17.3,34-22.1,52.7
                        c-0.6,2.4-1.1,4.9-1.9,7.2c-0.3,1-1.1,1.7-1.7,2.6c-0.8-0.9-2-1.7-2.2-2.6c-1.4-7.3-2.4-14.8-3.9-22.1c-5.3-25.9-9.8-52-22.2-75.9
                        c-1.3-2.5-2.6-5-3.8-7.6c-5.8-11.9-15.2-17.7-28.6-16.6c-3.3,0.2-6.5,0-10.8,0c1.6-3.1,2.7-5.5,4-7.7c14.1-24.2,28.2-48.4,38-74.7
                        c5.1-13.8,9.2-28.1,12.7-42.5c1.3-5.2,0.2-11.2-0.8-16.6c-1.1-5.8-5.8-8.1-11.4-6c-5.1,1.9-9.9,4.6-14.9,7c-1.7,0.8-3.4,1.7-6,1.8
                        c0.6-1.4,0.9-3,1.7-4.2c29.7-47,70.1-82,120-106.2c4.3-2.1,8.9-3.6,13.6-4.9c20.7-5.5,31.9,8.4,36.8,23.4c1.5,4.5,2.3,9.2,3.4,13.9
                        c-0.4,0.4-0.8,0.7-1.2,1.1c-1.7-0.9-3.5-1.7-5.1-2.7c-6.9-4.6-10.1-4.5-15.4,1.6c-19.2,21.9-33,46.8-39.5,75.3
                        c-2.1,9-2.8,18.3-4.1,27.5c-0.9,6.5,2,11.3,6.8,15.3c11.8,9.9,25.2,17.1,39.8,21.2c15.6,4.4,31.7,6.9,47.6,10.2
                        c3.2,0.7,4.7-1,3.8-3.9c-1.3-4.2-2.8-8.6-5.1-12.3c-4.1-6.9-8.9-13.4-13.4-20.1c-0.6,0.1-1.1,0.2-1.7,0.2
                        c-1.6,4.7-3.2,9.4-5.3,15.4c-6.4-11.6-7.3-23.1-7.7-34.8c-0.5-0.3-0.9-0.5-1.4-0.8c-3.4,3.9-6.9,7.7-11.2,12.5
                        c-0.8-15.7,4.9-28.9,8.5-44.2c-6.3,3.1-11.2,5.5-16.1,7.9c-0.2-0.5-0.5-0.9-0.7-1.4c8.5-12.1,19.4-21.5,33.3-28.9
                        c-11-5.6-21.7-5.8-32.2-7.5c14.3-5.6,29.1-6.4,44.2-5.9c0.1-0.4,0.2-0.8,0.4-1.3c-2.3-1.7-4.6-3.4-6.9-5.1c0.2-0.6,0.4-1.2,0.6-1.9
                        c11,1,22,2.1,33.7,3.2c-1.6-2.7-2.8-4.8-4.1-7c0.2-0.5,0.4-1,0.6-1.5c6.7,1.2,13.4,2.4,20.2,3.5c3.1,0.5,6.5,4.2,9.4-0.7
                        c0.2-0.4,2.3-0.1,3.3,0.4c5.4,2.1,10.9,4.1,16.1,6.7c9.1,4.7,10.7,10.1,6.5,19.2c-1.2,2.5-2.6,4.9-4.3,8c-8.7-8-14.5-1.8-20.2,3.6
                        c-2.3,2.1-4.5,2.4-7.5,2.6c-4,0.3-8.4,1.9-11.8,4.1c-3.2,2.1-6.1,3.4-10,3.4c-9.5,0-14.7,3.3-14.3,10.2c0.2,4.2,2.7,9,5.6,12.3
                        c7.3,8.4,15.7,15.8,23.1,24.2c7,7.9,13.4,16.4,19.8,24.9c1.6,2.2,2.7,5.1,3,7.8c0.5,4.2,2.2,5.4,6.2,4.7
                        c24.6-3.8,47.9-10.8,69-24.4c11.2-7.2,14.9-16.3,13-29.6c-4.9-33.9-18.3-63.8-39.9-90.1c-0.7-0.9-1.5-1.8-2.3-2.6
                        c-6.5-7.1-9.8-7.4-17.5-1.8c-1.5,1.1-3.2,1.8-6.1,3.5c3.1-12.1,4.8-22.8,12.3-31.5c9.6-11.2,21.8-12.2,34-6.7
                        c15.5,7.1,30.6,15.4,45.1,24.4c33.5,20.9,59.7,49.3,81.3,82.1c1.6,2.4,2.9,5,5.1,8.6c-2.8-0.3-3.8-0.1-4.6-0.5
                        c-4.1-2.4-7.9-5.3-12.1-7.5c-11.7-5.9-18-2-18.7,11.2c-0.6,10.9,3,21,6.1,31.2c10,32.7,26.4,62.5,43.4,91.9c2,3.5,3.9,7.1,6.3,11.4
                        c-2.7,0-4.3,0.2-5.8,0c-16.5-1.9-25.9,1.6-33.2,14.9c-9.8,17.9-17,36.8-20.9,56.9c-3.2,16.8-6.5,33.6-9.8,50.4
                        c-0.2,1.1-1.2,2-1.8,3c-0.7-1-1.7-1.9-2-3c-4.9-18.7-9.7-37.4-20.1-54c-6.8-10.9-14.8-20-27.2-24.6c-16.1-5.9-30.7-3.4-43.8,7.7
                        c-1.5,1.3-3.4,2.2-5.7,3.6c-1.1-4.5-2-8-2.7-11.5c-2.9-13.2-11.8-21.2-23.7-25.6c-6.9-2.5-14.6-3.1-21.9-4
                        c-1.6-0.2-4.2,1.5-5.3,3.1c-4.6,6.4-8.2,13.7-13.3,19.7c-7.3,8.4-15.7,15.9-23.4,23.9c-8,8.4-15.2,17.4-18.2,28.8
                        c-6,22.7,0.8,41.8,18.2,56.8c9.3,8,19.9,14.6,30.1,21.4c22.8,15.2,39.9,34.8,47.1,61.7c3.7,13.7,4.2,27.9,2.2,42.1
                        C368,609.8,366,621.9,364,635.1z M287.7,140.8c5.7,6,11.6,3.1,17.3,1.7C299.7,139.1,294.1,139.9,287.7,140.8z"/>
                    <path d="M284.3,157.8c6.4-0.9,12-1.7,17.3,1.7C295.9,160.9,290,163.8,284.3,157.8z"/>
                </g>
            </svg>
            <div className={`circleSound`}></div>
            <div className={`circleSound one ${props.playing ? "active" : "inactive"}`}></div>
            <div className={`circleSound two ${props.playing ? "active" : "inactive"}`}></div>
            <div className={`circleSound three ${props.playing ? "active" : "inactive"}`}></div>
        </div>
    )
}