import { useCallback, useEffect, useRef, useState } from "react";
import "./App.css";
import axios from "axios";
import Player from "./components/Player";
import Sound from "./components/Sound";

var AMOUNT_RADIOS_TO_LOAD = 20;

let mouseDownAllStationsRadios = false;
let startXAllStationsRadios, scrollLeftAllStationsRadios;

function App ()
{
	const innerAllButtonsCountriesRef = useRef();
	const allStationsRadiosRef = useRef();
	const [radios, setRadios] = useState([]);
	const [serverCurrent, setServerCurrent] = useState("");
	const [nameCurrent, setNameCurrent] = useState("");
	const [valueSearching, setValueSearching] = useState("");
	const [playing, setPlaying] = useState(false)
	const [codeCountryCurrent, setCodeCountryCurrent] = useState("US");
	const [allCountries, setAllCountries] = useState([]);
	const [preLoadedStart, setPreLoadedStart] = useState(false);
	const [amountRadiosLoaded, setAmountRadiosLoaded] = useState(AMOUNT_RADIOS_TO_LOAD);

	const start = useCallback(async () => {
		
		if (!preLoadedStart)
		{
			await axios.get("https://at1.api.radio-browser.info/json/countries")
			.then(data => {
				let all = data.data;
				let countries = [];
				let activeCountry = {};
				all.forEach(c => {
					countries.push(
						{
							name: c.name,
							code: c.iso_3166_1
						}
					);
				});
				countries = countries.filter(c => {
					if (c.code !== codeCountryCurrent) { return true; }
					else { 
						activeCountry = c;
						return false; 
					}			
				});
				countries.unshift(activeCountry);
				setAllCountries(countries);
			});
	
			let radios = [];
			radios = await getRadios();
	
			setRadios(radios);
			setPreLoadedStart(true);
		}

	}, [codeCountryCurrent, preLoadedStart, setRadios, setPreLoadedStart]);

	const setEventsListenersFromAllStationsRadios = useCallback(() => {
		let allStationsRadios = allStationsRadiosRef.current;
		allStationsRadios.addEventListener("mousemove", moveAllStationsRadios, false);
		allStationsRadios.addEventListener("mousedown", startDraggingAllStationsRadions, false);
		allStationsRadios.addEventListener("mouseup", stopDraggingAllStationsRadions, false);
		allStationsRadios.addEventListener("mouseleave", stopDraggingAllStationsRadions, false);

	}, [allStationsRadiosRef]);

	const searching = useCallback(search, [valueSearching, codeCountryCurrent, amountRadiosLoaded]);

	useEffect(() => {
		setEventsListenersFromAllStationsRadios();
		start();
		searching();
	}, [codeCountryCurrent, valueSearching, start, searching, setEventsListenersFromAllStationsRadios]);

	function moveAllStationsRadios (e)
	{
		e.preventDefault();
		if(!mouseDownAllStationsRadios) { return; }
		const x = e.pageX - allStationsRadiosRef.current.offsetLeft;
		const scroll = x - startXAllStationsRadios;
		allStationsRadiosRef.current.scrollLeft = scrollLeftAllStationsRadios - scroll;
	}

	function startDraggingAllStationsRadions (e)
	{
		mouseDownAllStationsRadios = true;
		startXAllStationsRadios = e.pageX - allStationsRadiosRef.current.offsetLeft;
		scrollLeftAllStationsRadios = allStationsRadiosRef.current.scrollLeft;
	}

	function stopDraggingAllStationsRadions (e)
	{
		mouseDownAllStationsRadios = false;
	}

	function play (s)
	{
		setServerCurrent(s.url);
		setNameCurrent(s.name);
		setPlaying(true);
	}

	function scrolling (type)
	{
		let inner = innerAllButtonsCountriesRef;
		let left = inner.current.scrollLeft;
		let amount = (type === "before") ? (left - 150) : (left + 150);
		amount = (type === "initial") ? (-left) : amount;
		inner.current.scrollLeft = amount;
	}

	function more ()
	{
		let amountToLoad = (amountRadiosLoaded + AMOUNT_RADIOS_TO_LOAD);
		setAmountRadiosLoaded(amountToLoad);
		search();
	}

	async function search ()
	{
		let searching = await valueSearching;
		let codeCountry = await codeCountryCurrent;
		let radios = await getRadios(codeCountry);

		// console.log("searching: ", searching);
		// console.log("codeCountry: ", codeCountry);
		// console.log("amountRadiosLoaded: ", amountRadiosLoaded);
		
		searching = searching.toString().toLowerCase();

		radios = radios.filter(s => {
			if (s.name.toString().toLowerCase().includes(searching)) {  return true; }
			return false;
		});
		
		radios = radios.slice(0, amountRadiosLoaded);
		
		radios.push(
			{
				name: `+${AMOUNT_RADIOS_TO_LOAD}`,
				typeButton: "MORE"
			}
		);

		setRadios(radios);
	}

	async function getRadios (codeCountryCurrent = null)
	{
		let url = "";
		let radios = [];

		if (codeCountryCurrent)
		{
			url = `https://at1.api.radio-browser.info/json/stations/bycountry/${codeCountryCurrent}`;
		}
		else
		{
			url = "https://at1.api.radio-browser.info/json/stations/topclick/5";
		}

		await axios.get(url)
		.then(data => {
			let all = data.data;

			all.forEach(s => {
				let url = s.url_resolved;

				if (s.favicon && url.toString().includes("https"))
				{
					radios.push(
						{
							url,
							name: s.name,
							image: s.favicon,
							typeButton: "RADIO"
						}
					);
				}
			});
		});

		return radios;
	}

    return (
        <div className="app">
			<div className="headerApp">
				<h1 className="title">Dragon's Radios</h1>
			</div>
			<div className="gradient"></div>
			<div className="bodyApp">
				<div className="col-12 col-md-2 colPlayBodyApp">
					<Sound playing={playing} />
				</div>
				<div className="col-12 col-md-8 colPlayBodyApp middle">
					<Player 
						url={serverCurrent} 
						playing={playing} 
						name={nameCurrent} 
						onTogglePlaying={() => setPlaying(!playing)} 
					/>
					<div className="areaSearchBodyApp">
						<input 
							type="text" 
							placeholder="search for radios, states, cities..."
							className="inputDefault inputSearchBodyApp"
							value={valueSearching}
							onChange={async (e) => { setValueSearching(e.target.value); }}
							onKeyDown={async (e) => { if (e.key === "Enter") { search(); } }}
						/>
						<button 
							className="buttonDefaultApp buttonSearchBodyApp"
							onClick={() => search()}
						>
							<i className="fas fa-search iconButtonSeachBodyApp"/>
						</button>
					</div>
					<div className="allCountriesSearchBodyApp">
						<button 
							className="buttonDefaultApp buttonStepApp"
							onClick={() => scrolling("before")}
						>
							<i className="fas fa-angle-left"></i>
						</button>
						<div className="allButtonCountriesApp" ref={innerAllButtonsCountriesRef}>
							{
								allCountries.map((c, i) => {
									return (
										<button
											key={i}
											className={`buttonDefaultApp buttonCountryBodyApp ${c.code === codeCountryCurrent ? "active" : "inactive"}`}
											onClick={async () => { 
												await setAmountRadiosLoaded(AMOUNT_RADIOS_TO_LOAD);
												await setCodeCountryCurrent(c.code);
											}}
										>
											{c.name}
										</button>
									)
								})
							}
						</div>
						<button
							className="buttonDefaultApp buttonStepApp"
							onClick={() => scrolling("after")}
						>
							<i className="fas fa-angle-right"></i>
						</button>
					</div>
					<div className="allStationsBodyApp" ref={allStationsRadiosRef}>
						<div className="scrollStationsBodyApp">
							{
								radios.map((s, index) => {
									let active = (serverCurrent === s.url) ? true : false
									return (
										<button
											className={`buttonDefaultApp buttonStationBodyApp ${active && "active"}`}
											key={index}
											onClick={() => (s.typeButton) === "RADIO" ? play(s) : more()}
										>
											{ s.image && <img src={s.image} alt="" className="imageStationsBodyApp"/> }
											<span className="labelButtonStationsBodyApp">{s.name}</span>
											{s.typeButton === "RADIO" && <span className="helpLabelButtonStationsBodyApp">{s.name}</span>}
											<span className="numberButtonStationsBodyApp">#{index + 1}</span>
										</button>
									)
								})
							}
						</div>
					</div>
				</div>
				<div className="col-12 col-md-2 colPlayBodyApp">
					<Sound playing={playing} />
				</div>
			</div>
			<div className="footerApp">
				<a href="https://ewertondias.com" className="linkDefault" target="_blank" rel="noreferrer">ewertondias.com</a>
			</div>
        </div>
    );
}

export default App;
